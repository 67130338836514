'use strict';

// Document Javascript pour le site paysan-breton.fr

// --- Integrations ---
import './integrations/bootstrap'; // integrates some bootstrap classes into wordpress

// --- Custom components ---
import "./components/simplebar.min.js"  // npm install simplebar --save-dev
import './components/backtotop';
// import './components/jquery.floatit.js'; // Pub Grand Angle fixe au scroll */** Changement PB ne veut plus de Grand Angle en fixe **/
//import './header/sticky_header.js'; // Header fixe au scroll -> enqueue
import './header/jquery.stickyalert';
import './components/jquery.video.js';
import './components/iframeResizer.min.js'; // Iframe Resizer - Redimensionnement automatique de la hauteur et de la largeur des iFrames identiques et inter-domaines pour s'adapter à leur contenu.

  // import './components/custom-youtube-slider.js'; // Player vidéo - https://www.jqueryscript.net/gallery/custom-youtube-gallery.html
  // Javascript document for WordPress theme Paysan Breton

  document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/g, '') + ' js';
  /* Correction du bug des ancres sous Chrome
   * @see http://www.nczonline.net/blog/2013/01/15/fixing-skip-to-content-links/
   * @see http://blog.atalan.fr/des-liens-devitement-astucieux/
   */

  window.addEventListener('hashchange', function (event) {
    var element = document.getElementById(location.hash.substring(1));

    if (element) {
      if (!/^(?:a|select|input|button)$/i.test(element.tagName)) {
        element.tabIndex = -1;
      }

      element.focus();
    }
  }, false);
  
  /* Liens d’évitements > Persistance de l’affichage
   * @see http://blog.atalan.fr/des-liens-devitement-astucieux/
   */

  [].forEach.call(document.querySelectorAll('.skip'), function (el) {
    el.addEventListener('focus', function () {
      el.classList.add('show');
    });
  });
  jQuery(document).ready(function () {
    // // Smooth Scrolling
    // // Select all links with hashes
    jQuery('a[href="#main"]') // Remove links that don't actually link to anything
    .not('[href="#"]').not('[href="#0"]').click(function (event) {
      // On-page links
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        // Figure out element to scroll to
        var target = jQuery(this.hash);
        target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']'); // Does a scroll target exist?

        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          jQuery('html, body').animate({
            scrollTop: target.offset().top
          }, 1000, function () {
            // Callback after animation
            // Must change focus!
            var $target = jQuery(target);
            $target.focus();

            if ($target.is(":focus")) {
              // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable

              $target.focus(); // Set focus again
            }
          });
        }
      }
    }); // Search Icon with full screen
    // Open the full screen search box
    
    // Correctif du megamenu pour le menu principal au clic
    function handleDropdownClick(e) {
      // Trouver l'élément parent avec la classe "dropdown"
      var dropdown = e.target.closest('.dropdown');
      
      if (dropdown) {
        // Trouver le menu et le lien nav-link
        var menu = dropdown.querySelector('.dropdown-menu');
        var navLink = dropdown.querySelector('.nav-link');
    
        if (dropdown.classList.contains('show')) {
          // Si le dropdown est déjà ouvert, le fermer
          dropdown.classList.remove('show');
          menu.classList.remove('show');
          if (navLink) {
            navLink.setAttribute('aria-expanded', 'false');
          }
        } else {
          // Sinon, ouvrir le dropdown
          dropdown.classList.add('show');
          menu.classList.add('show');
          if (navLink) {
            navLink.setAttribute('aria-expanded', 'true');
          }
    
          // Ajouter un écouteur pour fermer le dropdown si on clique à l'extérieur
          document.addEventListener('click', function outsideClickListener(event) {
            if (!dropdown.contains(event.target)) {
              dropdown.classList.remove('show');
              menu.classList.remove('show');
              if (navLink) {
                navLink.setAttribute('aria-expanded', 'false');
              }
              document.removeEventListener('click', outsideClickListener);
            }
          });
        }
      }
    }
    
    // Ajouter un écouteur d'événements pour gérer les clics
    document.body.addEventListener('click', handleDropdownClick);

    jQuery('a[href="#search"]').on('click', function (event) {
      event.preventDefault();
      jQuery('#search-overlay').toggleClass('active');
      jQuery('#searchform').addClass('active');
      jQuery('.search-field').focus();
      jQuery('.floating').addClass('visually-hidden'); // Le grand Angle est fixe passe donc au-dessus du offcanvas
    });
    jQuery('#closebtn').on('click', function (event) {
      event.preventDefault();
      jQuery('#search-overlay').removeClass('active');
      jQuery('#searchform').removeClass('active');
      jQuery('.floating').removeClass('visually-hidden'); // Le grand Angle est fixe passe donc au-dessus du offcanvas
    });
    jQuery('body').keyup(function (e) {
      // Même chose qu’au dessus mais au clavier sur ESC
      if (e.keyCode == 27) {
        // Code de la touche ESC du clavier
        jQuery('#search-overlay').removeClass('active');
        jQuery('#searchform').removeClass('active');
        jQuery('.floating').removeClass('visually-hidden'); // Le grand Angle est fixe passe donc au-dessus du offcanvas
      }
    });
    
    // Top bar - Plugin jquery.stickyalert.js

    jQuery('#topbar').stickyalert({
      cookieRememberDays: '7' // in days
    }); 
    
    // Carousel Bootstrap pour Vidéos YT

    jQuery('.video-thumbs--indicators .video-thumbs--slide').on('click', function () {
      jQuery(this).addClass('active');
      jQuery(this).siblings().removeClass('active');
      var vid_num_incount = jQuery('.videos-thumbs--header-number');
      var video_number = jQuery(this).find('.video-number').text(); // alert(video_number);

      jQuery(vid_num_incount).empty();
      jQuery(vid_num_incount).append(video_number);
    }); // Ouverture d’un nouvel onglet pour les location.href avec ajout de la classe .external
    // https://stackoverflow.com/questions/1574008/how-to-simulate-target-blank-in-javascript

    jQuery('.external').click(function (e) {
      e.preventDefault();
      window.open(this.href);
    });
    /**
     * Fixed Sidebar on scroll
     * https://jsfiddle.net/rossipedia/VtPcm/
     */

    var fixed_sidebar = jQuery('.right-sidebar-container');
    jQuery(fixed_sidebar).outerWidth();
    jQuery(fixed_sidebar).offset();
    jQuery('#posts-nav-label').offset(); // Bas de la Sidebar latérale gauche

    /**
    * Bouton de copie d’url de la page courante sur le sharer de post
    * https://codepen.io/andrea89/pen/xYJLxG
    */

    var tempInput = jQuery('<input>');
    var currentUrl = jQuery(location).attr('href');
    jQuery('.clipboard').on('click', function () {
      jQuery('body').append(tempInput);
      tempInput.val(currentUrl).select();
      document.execCommand('copy');
      tempInput.remove();
      jQuery('.tip').toggle('slow');
      jQuery('.tip-text').text('Copier dans le presse-papier !');
    }); // Construction d’un chapo pour les anciens articles mal formatés (h5 pour le chapo)

    var chapo = jQuery('.single-header--chapo');
    var chapo_text = jQuery(this).find('.type-post .entry-content h5:first').text();
    var chapo_text_container = jQuery(this).find('.type-post .entry-content h5:first');
    jQuery(chapo).append(chapo_text); // On place le texte récupéré sous le titre du post

    jQuery(chapo_text_container).remove(); // Bouton avec flèche à droite

    jQuery('.arrow-white').append('<span class="icon btn-arrow" role="img" aria-hidden="true"></span>');
    /**
     * Masquer les espaces réservés du formulaire et les ajouter en tant qu’étiquettes à la place
     *  */
    // Si nous sommes sur une page Woocommerce

    if (jQuery('body').hasClass('woocommerce-page')) {
      // loops through each form item
      jQuery('#billing_address_2_field, #billing_country').each(function () {
        var placeholder = jQuery(this).find('input').attr('placeholder'); //Create the label element

        var $label = jQuery("<label>").text(placeholder);
        jQuery(this).append($label);
      });
    }
    /**
     * Active class to current page in nav Woommerce Account page with jQuery 
     */


    jQuery(function () {
      var current = location.pathname;
      jQuery('woocommerce-MyAccount-navigation-link').each(function () {
        var $this = jQuery(this); // if the current path is like this link, make it active

        if ($this.attr('href').indexOf(current) !== -1) {
          $this.addClass('is-active');
        }
      });
    });
    jQuery('.woocommerce-MyAccount-navigation').on('click', '.woocommerce-MyAccount-navigation-link', function () {
      jQuery('.woocommerce-MyAccount-navigation .woocommerce-MyAccount-navigation-link.is-active').removeClass('is-active');
      jQuery(this).addClass('is-active');
    }); // FAQ - Collapse

    jQuery('.accordion .accordion-item:nth-child(1) .accordion-header').addClass('active');
    jQuery('.accordion .accordion-item:nth-child(1) .accordion-collapse').slideDown();
    jQuery('.accordion-header').on('click', function () {
      if (jQuery(this).hasClass('active')) {
        jQuery(this).siblings('.accordion-collapse').slideUp();
        jQuery(this).removeClass('active');
      } else {
        jQuery('.accordion-collapse').slideUp();
        jQuery('.accordion-header').removeClass('active');
        jQuery(this).siblings('.accordion-collapse').slideToggle();
        jQuery(this).toggleClass('active');
      }
    }); // ::first-word selector
    // https://codepen.io/mel/pen/Drddpz

    jQuery('.woocommerce-loop-product__title, .single-product_title').html(function () {
      var text = jQuery(this).text().trim().split(' ');
      var first = text.shift();
      return (text.length > 0 ? '<span class="first-word">' + first + '</span> ' : first) + text.join(' ');
    });
    /**
     * WP PageNavi amélioration
     */

    jQuery(function () {
      // Page courante
      jQuery('.pagination li:not(.page-item) .current').parent().addClass('page-item active');
      jQuery('.pagination .current').addClass('page-link'); // Points de suspension

      jQuery('.pagination li:not(.page-item) .extend').parent().addClass('page-item');
      jQuery('.pagination .extend').addClass('page-link disabled dots');
    });
    /**
     * Changement de place du texte "publicité" dans ".adv-link"
     */

    jQuery('.banniere-homepage-avant-footer .pb-adlabel').prependTo('.adv-link').remove('.banniere-homepage-avant-footer .pb-adlabel');
    
    /**
     * Scroll to top
     * https://css-tricks.com/how-to-make-an-unobtrusive-scroll-to-top-button/
     */

    var scrollToTopBtn = document.querySelector('.scroll-to-top');
    var rootElement = document.documentElement;

    function handleScroll() {
      // Do something on scroll
      var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;

      if (rootElement.scrollTop / scrollTotal > 0.8) {
        // Show button
        scrollToTopBtn.classList.add('show');
      } else {
        // Hide button
        scrollToTopBtn.classList.remove('show');
      }
    }

    function scrollToTop() {
      // Scroll to top logic
      rootElement.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

    scrollToTopBtn.addEventListener('click', scrollToTop);
    document.addEventListener('scroll', handleScroll);
    jQuery(window).width();
    /* Test bug checkbox */

    if (jQuery('#rememberme').is(':checked')) {
      jQuery(this).addClass('checked');
      alert('Checked');
    }
    /* Appel du plugin Sticky Header */
    new Sticky('#header-wrapper'); // Récup hauteurs éléments

    jQuery('#topbar').outerHeight();
    jQuery('#topmenu').outerHeight();
    /* Ajout d'une classe css à la zone de contenu si le header est sticky */

    jQuery(window).on('scroll', function () {
      if (jQuery('#header-wrapper').hasClass('sticky')) {
        jQuery('#topbar').addClass('topbar-is-sticky');
        jQuery('#topmenu').addClass('sticky');
        jQuery('#page-wrapper').addClass('header-is-sticky');
        jQuery('.global-header').addClass('top-menu-is-sticky');
        jQuery('#wrapper-navbar').addClass('wrapper-navbar-is-sticky');
        jQuery('.a-la-une').addClass('a-la-une-sticky');
        jQuery('.col-first').removeClass('col-md-7').addClass('col-md-3');
        jQuery('.col-center').removeClass('d-none').addClass('col-md-5');
        jQuery('.col-last').removeClass('col-md-3').addClass('col-md-4');
      } else {
        jQuery('#header-wrapper').removeClass('sticky');
        jQuery('#topbar').removeClass('topbar-is-sticky');
        jQuery('#topmenu').removeClass('sticky');
        jQuery('#page-wrapper').removeClass('header-is-sticky');
        jQuery('.global-header').removeClass('top-menu-is-sticky');
        jQuery('#wrapper-navbar').removeClass('wrapper-navbar-is-sticky');
        jQuery('.a-la-une').removeClass('a-la-une-sticky');
        jQuery('.col-first').removeClass('col-md-3').addClass('col-md-7');
        jQuery('.col-center').removeClass('col-md-5').addClass('d-none');
        jQuery('.col-last').removeClass('col-md-5').addClass('col-md-3');
      }
    });
    /* Check si fermeture de la topBar modif class css */

    jQuery('.topbar--close').click(function () {
      jQuery('#topbar').toggleClass('is-hidden');
      jQuery('#topmenu').toggleClass('topbar-is-hidden');
      jQuery('#wrapper-navbar').toggleClass('topbar-is-hidden');
      jQuery('.pb-megaban').toggleClass('topbar-is-hidden').css('margin-top', '8%');
      jQuery('#page-navbar').toggleClass('topbar-is-hidden').css('padding-top', '5%');
    }); // On maintient les classes css si la topbar d'info est fermée au scroll

    jQuery(window).on('scroll', function () {
      if (jQuery('#topbar').hasClass('topbar-is-closed')) {
        jQuery('#topmenu').addClass('topbar-is-hidden');
        jQuery('#wrapper-navbar').addClass('topbar-is-hidden');
        jQuery('#page-wrapper').addClass('topbar-is-hidden');
      } else {
        jQuery('#topmenu').removeClass('topbar-is-hidden');
        jQuery('#wrapper-navbar').removeClass('topbar-is-hidden'); // jQuery('#page-wrapper').removeClass('topbar-is-hidden');
      }
    });
    /* Toogle les accordéons */
    jQuery('.accordion-button').click(function () {
      jQuery(this).toggleClass('collapsed');
      jQuery('.accordion-collapse').toggleClass('show');
    });
    /* BS5 css Classe variations select Attributs Carte Cadeau */

    jQuery('#forfait').addClass('form-select');
    /* Toogle sur la légende de l'image à la une en single */

    jQuery('.figcaption--info-icon').click(function () {
      jQuery('.figcaption--text').toggleClass('toggle');
    }); // in another js file, far, far away

    jQuery('.habillage-desktop').on('classChange', function () {
      jQuery('body').addClass('habillage-is-active');
    });
    var $target = jQuery('.habillage-desktop');
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.attributeName === 'class') {
          var attributeValue = jQuery(mutation.target).prop(mutation.attributeName);

          if (attributeValue.indexOf('pb-body-background') >= 0) {
            jQuery('body').addClass('habillage-is-active'); // do what you want
          }
        }
      });
    });
    observer.observe($target[0], {
      attributes: true
    });
    /* Changement attributs des iframes Météo */

    jQuery('.meteoconcept-widget').removeAttr('width');
    jQuery('.meteoconcept-widget').removeAttr('height');
    jQuery('.meteoconcept-widget').contents().find('body').css('backgroundColor', '#404040');
    /* Plugin JS Iframe Resizer */

    iFrameResize({
      default: true
    }, 'meteoconcept-widget');
    
  });